import { Authenticator } from "@aws-amplify/ui-react";

import { Protected } from "./components/hg/Protected";
import { RequireAuth } from "./config/RequireAuth";
import { Login } from "./components/hg/Login";
import { ProtectedSecond } from "./components/hg/ProtectSecond";
import { Home } from "./components/hg/Home";
import { Layout } from "./components/hg/Layout";
import { QueryClient, QueryClientProvider } from "react-query";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./App.css";
import Details from "./components/hg/Details";
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  },
});

function MyRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route
            path="/protected"
            element={
              <RequireAuth>
                <Protected />
              </RequireAuth>
            }
          />
          <Route
            path="/protected2"
            element={
              <RequireAuth>
                <ProtectedSecond />
              </RequireAuth>
            }
          />
          <Route
            path="/details"
            element={
              <RequireAuth>
                <QueryClientProvider client={queryClient}>
                  <Details />
                </QueryClientProvider>
              </RequireAuth>
            }
          />
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Login />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

function App() {
  return (
    <Authenticator.Provider>
      <MyRoutes />
    </Authenticator.Provider>
  );
}

export default App;
