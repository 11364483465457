import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useAuthenticator, Button, Heading, View } from "@aws-amplify/ui-react";

export function Layout() {
  const { route, signOut } = useAuthenticator((context) => [
    context.route,
    context.signOut,
  ]);
  const navigate = useNavigate();

  function logOut() {
    console.log("....loging out");
    signOut();
    navigate("/login");
  }
  return (
    <>
      <nav>
        <Button onClick={() => navigate("/")}>Home</Button>
        <Button onClick={() => navigate("/protected")}>Test</Button>
        <Button onClick={() => navigate("/details")}>Details</Button>
        <Button onClick={() => navigate("/protected2")}>Test2</Button>
        {route !== "authenticated" ? (
          <Button onClick={() => navigate("/login")}>Login</Button>
        ) : (
          <Button onClick={() => logOut()}>Logout</Button>
        )}
      </nav>
      <Heading level={1}>Beta WIP ⚠️ </Heading>
      <View>
        {route === "authenticated" ? "You are logged in!" : "Try Login!"}
      </View>

      <Outlet />
    </>
  );
}
