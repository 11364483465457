/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
let awsmobile = {};
if (process.env.NODE_ENV === "development") {
  awsmobile = {
    aws_project_region: "us-east-1",
    aws_cognito_identity_pool_id:
      "us-east-1:83203071-c9cc-4d38-9eaf-e4c1e202cf5e",
    aws_cognito_region: "us-east-1",
    aws_user_pools_id: "us-east-1_GF7rQ6BRg",
    aws_user_pools_web_client_id: "31qe9is28etra19bu09f9k59bd",
    oauth: {
      domain: "humminggenie.auth.us-east-1.amazoncognito.com",
      scope: ["email", "openid", "phone", "profile"],
      redirectSignIn: "http://localhost:3000",
      redirectSignOut: "http://localhost:3000/logout",
      responseType: "code",
    },
    federationTarget: "COGNITO_USER_AND_IDENTITY_POOLS",
    aws_cognito_username_attributes: ["EMAIL"],
    aws_cognito_social_providers: ["GOOGLE", "APPLE"],
    aws_cognito_signup_attributes: ["EMAIL"],
    aws_cognito_mfa_configuration: "OFF",
    aws_cognito_mfa_types: [],
    aws_cognito_password_protection_settings: {
      passwordPolicyMinLength: 8,
      passwordPolicyCharacters: [
        "REQUIRES_LOWERCASE",
        "REQUIRES_UPPERCASE",
        "REQUIRES_NUMBERS",
        "REQUIRES_SYMBOLS",
      ],
    },
    aws_cognito_verification_mechanisms: ["EMAIL"],
  };
} else {
  awsmobile = {
    aws_project_region: "us-east-1",
    aws_cognito_identity_pool_id:
      "us-east-1:83203071-c9cc-4d38-9eaf-e4c1e202cf5e",
    aws_cognito_region: "us-east-1",
    aws_user_pools_id: "us-east-1_GF7rQ6BRg",
    aws_user_pools_web_client_id: "31qe9is28etra19bu09f9k59bd",
    oauth: {
      domain: "humminggenie.auth.us-east-1.amazoncognito.com",
      scope: ["email", "openid", "phone", "profile"],
      redirectSignIn: "https://humminggenie.com",
      redirectSignOut: "https://humminggenie.com/logout",
      responseType: "code",
    },
    federationTarget: "COGNITO_USER_AND_IDENTITY_POOLS",
    aws_cognito_username_attributes: ["EMAIL"],
    aws_cognito_social_providers: ["GOOGLE", "APPLE"],
    aws_cognito_signup_attributes: ["EMAIL"],
    aws_cognito_mfa_configuration: "OFF",
    aws_cognito_mfa_types: [],
    aws_cognito_password_protection_settings: {
      passwordPolicyMinLength: 8,
      passwordPolicyCharacters: [
        "REQUIRES_LOWERCASE",
        "REQUIRES_UPPERCASE",
        "REQUIRES_NUMBERS",
        "REQUIRES_SYMBOLS",
      ],
    },
    aws_cognito_verification_mechanisms: ["EMAIL"],
  };
}

export default awsmobile;
