import { useQuery } from "react-query";
import fetchWishList from "../fetchWishList";
export function WishListByReactQUery() {
  const { isLoading, error, data } = useQuery("wishList", fetchWishList);
  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>An error has occurred: {error.message}</p>;

  return (
    <div>
      <h3>Wish List - displaying {data.items.length} wish by users</h3>
      {data.items.map((wish) => (
        <div key={wish.wishId}>
          {wish.title} | {wish.wishId}
        </div>
      ))}
    </div>
  );
}
